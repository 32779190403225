















import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'

@Component({
  components: {
    ListView,
  },
})
export default class List extends Vue {
  headers = []
  routes = {}
  topActions = []

  mounted() {
    this.headers = [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Percent',
        value: 'percent',
      },
      {
        text: 'Account',
        value: 'account',
        filter: { disable: true },
      },
      {
        text: 'VAT code',
        value: 'vatCode',
      },
      {
        text: 'External VAT code',
        value: 'externalVatCode',
      },
      {
        text: 'VAT Category',
        value: 'vatCategory',
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]

    this.topActions = [
      {
        id: 'new',
        route: () => {
          return {
            name: 'Settings/Economy/VatCodes/New',
            params: { id: '0' },
          }
        },
      },
    ]
  }

  private get rowActions() {
    const actions: any = [
      {
        id: 'edit',
        route: (item) => {
          return {
            name: 'Settings/Economy/VatCodes/Edit',
            params: { id: item.id },
          }
        },
      },
    ]
    actions.push({ id: 'delete' })
    return actions
  }
}
